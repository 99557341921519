import React, { useEffect, useRef, useState } from 'react'
import Fab from '@material-ui/core/Fab'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/styles'
import AudiotrackIcon from './headphones-monochrome.png'
import PDFIcon from './pdf_icon.jpg'

const useStyles = makeStyles({
  card: {
    width: '100%',
    height: 'calc(100vh - 400px)'
  },
})

function StoryItem(props) {
  const classes = useStyles()

  const {
    story,
    selectedStoryId,
    buttonClicked,
    isMobile,
    isVisible,
    selectYear,
  } = props

  const theme = useTheme()

  const [image, setImage] = useState('')

  const inputEl = useRef(null)

  useEffect(() => {
    if (selectedStoryId === story._id && !isMobile) {
      window.scrollTo(0, inputEl.current.offsetTop - 300) // since the min height of cover photo is 300px
    }
  }, [selectedStoryId, story._id])

  useEffect(() => {
    if (isVisible) {
      selectYear(story)
    }
  }, [isVisible])

  useEffect(() => {
    if (story.image) {
      if (story.image.includes('.mp3')) {
        setImage(AudiotrackIcon)
      } else if (story.image.includes('.pdf')) {
        setImage(PDFIcon)
      } else {
        setImage(story.image)
      }
    }
  }, [story.image])

  // NOT mobile
  if (!isMobile) {
    return (
      <div
        ref={inputEl}
        style={{
          display: 'flex',
          minHeight: 230,
          marginTop: 27,
          marginBottom: 27,
        }}
      >
        <div
          style={{
            width: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Card className={classes.card} onClick={() => buttonClicked(story)}>
            {image.length > 0 &&
             <CardActionArea>
               <CardMedia  style={{ height: 'calc(100vh - 400px)' }} image={image} />
             </CardActionArea>
            }
          </Card>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            margin: 32,
            color: '#63BFF3',
          }}
        >
          <div
            onClick={() => buttonClicked(story)}
            style={{
              cursor: 'pointer',
              fontWeight: 500,
              fontSize: 26,
              lineHeight: '30px',
              color: theme.palette.primary.main,
            }}
          >
            {story.title}
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: 18,
              lineHeight: '21px',
              color: theme.palette.secondary.main,
              marginTop: 6,
              marginBottom: 6,
            }}
          >
            {story.startYear}
          </div>
          <div style={{ fontSize: 15, lineHeight: '18px', color: '#7A7A7A' }}>
            {story.description}
          </div>
          <Fab
            onClick={() => buttonClicked(story)}
            variant="extended"
            size="small"
            style={{
              color: 'white',
              backgroundColor: theme.palette.secondary.main,
              marginTop: 18,
              marginRight: 'auto',
              marginBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            View Story
          </Fab>
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginLeft: 27,
        marginRight: 27,
      }}
    >
      <div
        style={{
          marginRight: 'auto',
          marginTop: 16,
          marginBottom: 16,
          color: theme.palette.primary.main,
          fontWeight: 'bold',
          fontSize: 20,
        }}
      >
        {story.startYear}
      </div>

      <Card className={classes.card}>
        <CardActionArea style={{position: 'relative', height: '100%'}}>
          <div
            onClick={() => buttonClicked(story)}
            style={{
              height: '100%',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.54), rgba(255, 255, 255, .4)), url(${image})`,
            }}
          />
          <div
            style={{
              position: 'absolute',
              left: 10,
              color: 'white',
              bottom: 0,
            }}
          >
            <p style={{ fontSize: 26, lineHeight: '30px', color: 'white' }}>
              {story.title}
            </p>
            <Fab
              onClick={() => buttonClicked(story)}
              variant="extended"
              size="small"
              style={{
                color: 'white',
                backgroundColor: theme.palette.secondary.main,
                marginTop: 'auto',
                marginRight: 'auto',
                marginBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
                View Story
            </Fab>
          </div>
        </CardActionArea>
      </Card>
    </div>
  )
}

export default StoryItem
