import React, { useEffect, useState, useRef } from 'react'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Image from './Image'
import Video from './Video'
import Audio from './Audio'
import Text from './Text'
import Carousel from 'nuka-carousel'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { makeStyles } from '@material-ui/styles'
import BackButton from './back.png'
import DateIcon from './date_icon.png'
import GoogleMaps from './GoogleMaps'
import MetaData from './metadata/MetaData'
import Tags from './tags/Tags'
import YouTube from './YouTube'
import { StoryItemPDF } from '@Pass-It-Down/story-item-react'
import PDFPreviewDialog from './PDFPreviewDialog'
import CloseButton from './close.png'
import api from '../exhibitApi'



const useStyles = makeStyles(theme => {
  return {
    title: {
      display: 'flex',
      backgroundColor: theme.palette.primary.main,
      fontSize: 32,
      color: 'white',
      borderBottom: `3px solid ${theme.palette.secondary.main}`,
      padding: 8,
      fontWeight: 600,
      letterSpacing: '-1px'
    },
    close: {
      width: 40,
      height: 40,
      background: `url(${CloseButton})center center/70% no-repeat rgba(0,0,0,.5)`,
      borderRadius: '100%',
      cursor: 'pointer',
      position: 'absolute',
      top: 10,
      right: 10
    },
    arrow: {
      position: 'fixed',
      bottom: -20,
      width: '4em',
      height: '4em',
      left: 0,
      right: 0,
      margin: 'auto',
      color: 'white'
    },
    back: {
      position: 'fixed',
      bottom: '26%',
      width: 150,
      paddingTop: '10%',
      background: `url(${BackButton})left center/contain no-repeat`
    },
    description: {
      padding: '0 8px 10px',
      fontWeight: 400,
      fontSize: 20,
      color: '#666'
    },
    locationSection: {
      display: 'flex',
      flexDirection: props => props.isMobile ? 'column' : 'unset',
      color: theme.palette.secondary.main,
      padding: '15px 3%'
    },
    dateIcon: {
      background: `url(${DateIcon})left top/22px no-repeat`,
      paddingLeft: 28,
      marginRight: 15,
      fontWeight: 600,
      letterSpacing: '-.8px',
      fontSize: 15,
      height: 22,
      color: 'inherit'
    },
    location: {
      marginTop: props => props.isMobile ? 10 : 0,
      fontFamily: 'Avenir, Avenir Next, \'Montserrat\'',
      color: '#666'
    }
  }
})


function Popup(props) {
  const { app, settings, close, isMobile, tagClick, mapStyle } = props
  const classes = useStyles(props)

  const [assets, setAssets] = useState([])
  const [tags, setTags] = useState([])
  const dialogContentRef = useRef(null)
  const stackedRef = useRef(null)

  const [slideCurrent, setSlideCurrent] = useState(0)
  const [keyboardDownClicked, setKeyboardDownClicked] = useState(false)
  const [arrowVisible, setArrowVisible] = useState(true)
  const [openPDFPreviewDialog, setOpenPDFPreviewDialog] = useState(false)
  const [PDFLink, setPDFLink] = useState('')


  useEffect(() => {
    async function getAssets(id) {
      const story = await api.story().getById(id)
      setAssets(story.assets)
    }

    async function getTags(id) {
      const tags = await api.story().getTagsById(id)
      setTags(tags)
    }

    if (settings.story._id) {
      getTags(settings.story._id)
      getAssets(settings.story._id)
    }

  }, [settings])


  const handleClose = () => {
    setAssets([])
    close()
  }

  const isImage = () => {
    const asset = assets[slideCurrent]
    if (!asset) return false
    return asset.isImage
  }

  useEffect(() => {
    if (assets.length > 0 && !isImage()) {
      setArrowVisible(false)
    } else {
      setArrowVisible(true)
    }
  }, [slideCurrent])

  const afterSlide = (currentSlide) => {
    if (keyboardDownClicked) {
      setKeyboardDownClicked(false) // reset
      setArrowVisible(true)
    }

    setSlideCurrent(currentSlide)
  }

  const handleKeyboardDownClicked = () => {
    setKeyboardDownClicked(true)
    if (settings.story.storyTemplate === 'Stacked') {
      dialogContentRef.current.scrollBy({ top: dialogContentRef.current.parentElement.clientHeight, behavior: 'smooth' })
    }
    // prevent arrow from disappearing too quickly
    setTimeout(() => {
      setArrowVisible(false)
    }, 500)
  }

  const handleScroll = () => {
    const scrollTop = dialogContentRef.current.scrollTop
    if (scrollTop === 0) {
      setKeyboardDownClicked(false)
      if (shouldShowArrow()) {
        setArrowVisible(true)
      }

    } else {
      if (arrowVisible) setArrowVisible(false)
    }
  }

  const shouldShowArrow = () => {
    return isImage()
  }

  const handleShowPDF = (link) => {
    setPDFLink(link)
    setOpenPDFPreviewDialog(true)
  }

  const handleClosePDFPreviewDialog = () => {
    setPDFLink('')
    setOpenPDFPreviewDialog(false)
  }

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={'lg'}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={settings.open}>
        <MuiDialogContent
          dividers
          onScroll={handleScroll}
          ref={dialogContentRef}
          style={{ position: 'relative', padding: 0 }}>
          <div>
            <div className={classes.title}>
              {/* 85% width to prevent overlap of title with close button in mobile view */}
              <div style={{ width: '85%' }}>
                {settings.story.title}
              </div>
              <div style={{ flexGrow: 1 }} />
              <div className={classes.close} onClick={handleClose} />
            </div>
            <Typography classes={{
              root: classes.description
            }} gutterBottom style={{ marginTop: 16 }}>
              {settings.story.description}
            </Typography>
            <div className={classes.locationSection}>
              <span className={classes.dateIcon}>{settings.story.formatted_date}</span>
              <div style={{ display: settings.story.locationName === '' ? 'none' : 'block' }} className={classes.location}> - {settings.story.locationName}</div>
            </div>
          </div>
          {settings.story.storyTemplate === 'Gallery' &&
            <div>
              <Carousel
                heightMode='current'
                style={{ minHeight: 500, padding: '15px 10px' }}
                afterSlide={afterSlide}
                withoutControls={assets.length === 1}
                renderBottomCenterControls={null}>
                {assets.map((asset, index) => {
                  switch (asset.type) {
                  case 'IMAGE':
                    return (
                      <Image
                        key={index}
                        asset={asset}
                        isGallery={true}
                        keyboardDownClicked={keyboardDownClicked}
                        currentSlide={slideCurrent}
                        index={index} />
                    )

                  case 'VIDEO':
                    return (
                      <Video key={index} height={500} asset={asset} />
                    )

                  case 'YOUTUBE':
                    return (
                      <YouTube key={index} height={500} asset={asset} />
                    )

                  case 'AUDIO':
                    return (
                      <Audio key={index} height={500} asset={asset} />
                    )
                  case 'PDF':
                    return (
                      <StoryItemPDF key={index} asset={asset} showPDF={handleShowPDF} />
                    )
                  default:
                    if (asset.type !== 'TEXT') console.error('should not be here', asset.type)
                    return null
                  }
                })}
              </Carousel>
              {assets.filter(asset => asset.type === 'TEXT').map((asset, index) => (
                <div key={index} style={{ padding: '15px 1%', boxSizing: 'border-box' }}>
                  <Text asset={asset} />
                </div>
              ))}
            </div>}

          {settings.story.storyTemplate === 'Stacked' &&
            <ul ref={stackedRef} style={{ padding: '15px 10px' }}>
              {assets.map((asset, index) => {
                switch (asset.type) {
                case 'IMAGE':
                  return (
                    <li key={index} style={{ listStyleType: 'none' }}>
                      <Image asset={asset} height={500} isMobile={isMobile} />
                    </li>
                  )

                case 'VIDEO':
                  return (
                    <li key={index} style={{ listStyleType: 'none' }}>
                      <Video height={500} asset={asset} />
                    </li>
                  )

                case 'YOUTUBE':
                  return (
                    <li key={index} style={{ listStyleType: 'none' }}>
                      <YouTube height={500} asset={asset} />
                    </li>
                  )

                case 'AUDIO':
                  return (
                    <li key={index} style={{ listStyleType: 'none' }}>
                      <Audio height={500} asset={asset} />
                    </li>
                  )
                case 'TEXT':
                  return (
                    <li key={index} style={{ listStyleType: 'none', padding: '15px 1%', boxSizing: 'border-box' }}>
                      <Text asset={asset} />
                    </li>
                  )
                case 'PDF':
                  return (
                    <li key={index} style={{ listStyleType: 'none', padding: '15px 1%', boxSizing: 'border-box' }}>
                      <StoryItemPDF asset={asset} showPDF={handleShowPDF} />
                    </li>
                  )
                default:
                  return (
                    <li key={index} style={{ listStyleType: 'none' }}>
                      <Image asset={asset} />
                    </li>
                  )
                }
              })}
            </ul>
          }

          {settings.story.location &&
            settings.story.location.lat &&
            <GoogleMaps
              center={settings.story.location}
              location={settings.story.location}
              mapStyle={mapStyle} />}

          {app.viewTagsPublicly && tags.length > 0 && <Tags tags={tags} click={tagClick} />}
          {settings.story.metaData && <MetaData metaData={settings.story.metaData} />}

          {!isMobile && <div className={classes.back} onClick={handleClose} />}
          <KeyboardArrowDown
            style={{ display: arrowVisible ? 'block' : 'none' }}
            className={classes.arrow}
            onClick={handleKeyboardDownClicked} />
        </MuiDialogContent>
        {isMobile &&
          <MuiDialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </MuiDialogActions>}

        <PDFPreviewDialog
          open={openPDFPreviewDialog}
          PDFLink={PDFLink}
          close={handleClosePDFPreviewDialog} />

      </Dialog>
    </div>
  )
}

export default Popup
