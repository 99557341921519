
// TODO : zoom in on first story

import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import MarkerWrapper from './MarkerWrapper'


export class GoogleMaps extends Component {
  

  render() {

    const { location, mapStyle }= this.props
      
    return (
      <div style={{height: 300, width: '100%', margin: '30px 0'}}>
        <GoogleMapReact
          center={this.props.center}
          zoom={15}
          options={{ styles: mapStyle }}>
          <MarkerWrapper lat={location.lat} lng={location.lng} />
        </GoogleMapReact>
      </div>
    )
  }
}


export default GoogleMaps