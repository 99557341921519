import React, { useRef, useEffect } from 'react'
import { Transition } from 'react-transition-group'
import LineSeparator from './LineSeparator'
import { useTheme } from '@material-ui/core/styles'

import './Year.css'

const duration = 300

const defaultStyle = {
  transition: 'all .1s',
  transformOrigin: '0 50%',
}

const transitionStyles = {
  entering: { transform: 'scale(1)' },
  entered: { transform: 'scale(1.5)' },
  exiting: { transform: 'scale(1.5)' },
  exited: { transform: 'scale(1)' },
}

function Year(props) {
  const { year, clicked, selectedYear, isLast } = props

  const theme = useTheme()

  const yearRef = useRef(null)

  useEffect(() => {
    if (selectedYear === year.year) {
      yearRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [selectedYear])

  return (
    <Transition in={selectedYear === year.year} timeout={duration}>
      {(state) => (
        <div ref={yearRef}>
          <div
            onClick={() => clicked(year)}
            style={{
              marginTop: 20,
              marginBottom: 20,
              cursor: 'pointer',
              color:
                selectedYear === year.year
                  ? theme.palette.primary.main
                  : theme.palette.secondary.main,
              fontWeight: 'bold',
              fontSize: 20,
              lineHeight: '23px',
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            {year.year}
          </div>
          <div style={{ display: isLast ? 'none' : 'block' }}>
            <LineSeparator />
          </div>
        </div>
      )}
    </Transition>
  )
}

export default Year
