import React, { useEffect, useState } from 'react'
import VerticalProgress from './VerticalProgress'
import CoverPhoto from './CoverPhoto'
import StoryItem from './StoryItem'
import GoUpButton from './GoUpButton'
import TrackVisibility from 'react-on-screen'
import { sortStories } from '@Pass-It-Down/exhibition-client/src/storyHelper'
import api from './exhibitApi'


export default function Main(props) {
  const {
    app,
    appId,
    isMobile,
    handleButtonClicked,
    handleGoUpButtonClicked,
  } = props

  const [isScrolling, setIsScrolling] = useState(false)
  const [selectedYear, setSelectedYear] = useState(0)
  const [selectedStoryId, setSelectedStoryId] = useState('')
  const [stories, setStories] = useState([])
  const [years, setYears] = useState([])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    async function getStories(appId) {
      const stories = await api.story().getAllByExhibitId(appId)
      processStories(stories)
    }

    getStories(appId)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }

  }, [appId])

  useEffect(() => {
    if (stories.length === 0) return

    processYears()

  }, [stories])

  const inArr = (year, array) => {
    const obj = array.find(el => el.year === year)
    return obj !== undefined
  }

  const incrementCount = (year, array) => {
    const obj = array.find(el => el.year === year)
    obj.count += 1
  }

  const processYears = () => {
    const arr = []

    stories.map(story => {
      const year = story.startYear
      if (inArr(year, arr)) {
        incrementCount(year, arr)
      } else {
        const obj = { year, count: 1 }
        arr.push(obj)
      }
    })

    setYears(arr)
  }

  const processStories = (stories) => {
    if (stories.length === 0) {
      console.log('no stories found')
      return
    }

    stories = sortStories('chronological', stories)

    setStories(stories)
    setSelectedYear(stories[0].startYear)
  }

  let timer

  const handleScrollEnd = () => {
    setIsScrolling(false)
  }

  const handleScroll = () => {
    if (!isScrolling) {
      setIsScrolling(true)
    }
    clearTimeout(timer)
    timer = setTimeout(handleScrollEnd, 150)
  }

  const handleSelectYear = (story) => {
    const year = story.startYear
    setSelectedYear(year)
    const f = stories.find((story) => story.startYear === year)
    if (!isScrolling) {
      // if not scrolling set the year which will trigger a scroll
      setSelectedStoryId(f._id)
    }
  }

  const handleClick = (year) => {
    setSelectedYear(year.year)
    const f = stories.find((story) => story.startYear === year.year)
    setSelectedStoryId(f._id)
  }

  const getEndYear = () => {
    if (stories.length > 0) {
      const lastStory = stories[stories.length - 1]
      if (lastStory.endYear) {
        return lastStory.endYear
      }
      return 'Today'
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <CoverPhoto
        app={app}
        isMobile={isMobile}
        startYear={stories.length > 0 && stories[0].startYear}
        endYear={getEndYear()}
        image={app.image}
      />
      <div style={{ display: 'flex' }}>
        <VerticalProgress
          years={years}
          selectedYear={selectedYear}
          handleClick={handleClick}
          isMobile={isMobile}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            marginBottom: 20,
            marginTop: 30,
            paddingTop: isMobile ? 0 : 390
          }}
        >
          {stories.map((story, index) => (
            <TrackVisibility key={index}>
              <StoryItem
                selectedStoryId={selectedStoryId}
                story={story}
                buttonClicked={handleButtonClicked}
                isMobile={isMobile}
                selectYear={handleSelectYear}
              />
            </TrackVisibility>
          ))}
        </div>
      </div>
      <GoUpButton click={handleGoUpButtonClicked} />
    </div>
  )
}
