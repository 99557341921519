import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => {
  return {
    span: {
      fontSize: '105.01%',  
    },
    value: {
      color: theme.palette.primary.main
    }
  }
})

export default function Type(props){

  const classes = useStyles()
    
  const {type, value } = props

  return (
    <span className={classes.span}>
      {type}: <span className={classes.value}>{value}</span>
    </span>
  )
}