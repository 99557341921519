import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  caption: {
    display: 'flex',
    position: 'absolute',
    left: 0,
    color: '#fff',
    width: '100%',
    background: 'rgba(0,0,0,.5)',
    fontSize: 16,
    fontWeight: 600,
    fontStyle: 'italic',
    textAlign: 'left',
    padding: '5px 2% 5px 2%',
    paddingRight: 165,
    alignItems: 'center',
    borderRadius: '0 0 4px 4px'
  }
})

export default function Caption(props){

  const classes = useStyles()

  const { asset, click } = props

  const [hideOrShowAnnotations, setHideOrShowAnnotations] = useState('Hide Annotations')

  const handleClick = () => {
    click()
    if(hideOrShowAnnotations === 'Hide Annotations'){
      setHideOrShowAnnotations('Show Annotations')
    }else{
      setHideOrShowAnnotations('Hide Annotations')
    }
  }

  return (
    <div
      className={classes.caption} style={{
        display: asset.caption === '' ? 'none' : 'flex',
        bottom: asset.type === 'AUDIO' ? 50 : 0 }}>
      {asset.caption}
      <div
        onClick={handleClick}
        style={{
          display: (asset.type === 'IMAGE' && asset.annotations.length !== 0) ? 'block' : 'none',
          position: 'absolute',
          right: 10,
          textDecoration: 'underline'}}>
        {hideOrShowAnnotations}
      </div>
    </div>
  )
}