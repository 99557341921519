import { Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import api from './exhibitApi'



function CoverPhoto(props) {
  const [height, setHeight] = useState(390)
  const [loginRequired, setLoginRequired] = useState(false)


  const handleScroll = () => {
    const scrollTop = window.pageYOffset
    setHeight(Math.max(260, height - scrollTop))
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    async function requiresLogin(){
      const loginRequired = await api.user().requiresLogin(props.app._id)
      setLoginRequired(loginRequired)
    }

    requiresLogin()
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }    
  }, [])

  const logout = async () => {
    await api.user().logout()

  }

  const { image, startYear, endYear, isMobile, app } = props

  if (isMobile) {
    return (
      <div style={{ position: 'relative' }}>
        <div
          style={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            justifyContent: 'center',
            width: '100%',
            height: 440,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              backgroundImage: 'linear-gradient(0deg, rgba(0, 0, 0, 0.54), rgba(255, 255, 255, .4))',
              height: '100%',
              width: '100%',
            }}
          />
          <div
            style={{
              position: 'absolute',
              color: 'white',
              textAlign: 'center',
              marginTop: 16,
            }}
          >
            <div style={{ fontWeight: 500, fontSize: 39, lineHeight: '53px' }}>
              {app.name}
            </div>
            <div
              style={{
                marginTop: 14,
                fontWeight: 500,
                fontSize: 20,
                lineHeight: '23px',
              }}
            >
              From {startYear} to {endYear}
              
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        position: 'fixed',
        height: height,
        width: '100%',
        top: 0,
        left: 0,
        zIndex: 1,
      }}
    >
      <div style={{ position: 'relative' }}>
        <div>
          <div
            style={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              justifyContent: 'center',
              width: '100%',
              height: height,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                backgroundImage: 'linear-gradient(to right top, rgba(0, 0, 0, 0.54), rgba(255, 255, 255, .4))',
                height: '100%',
              }}
            />
          </div>
          <div
            style={{
              position: 'absolute',
              marginLeft: 34,
              color: 'white',
              bottom: height === 260 ? '40%' : 0,
              marginBottom: height === 260 ? 0 : 60,
            }}
          >
            <div style={{ fontWeight: 500, fontSize: 39, lineHeight: '46px' }}>
              {app.name} {loginRequired && <Button onClick={logout}>Logout</Button>}
            </div>
            <div
              style={{
                marginTop: 14,
                fontWeight: 500,
                fontSize: 20,
                lineHeight: '23px',
              }}
            >
              From {startYear} to {endYear}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CoverPhoto
