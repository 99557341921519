import React from 'react'
import Story from './Story'
import NoStoriesFound from '@material-ui/icons/PhotoLibrary'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  noStoriesFound: {
    width: '5em',
    height: '5em',
  },
})

function StoriesList(props) {
  const classes = useStyles()

  const { stories, showStory, app } = props

  const left = stories ? stories.filter((_, index) => index % 2 === 0) : []
  const right = stories ? stories.filter((_, index) => index % 2 === 1) : []

  // margin bottom is to ensure content is above footer of height 65px
  if (stories.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          margin: '20px 0',
          justifyContent: 'center',
          marginBottom: 65,
        }}
      >
        <div
          style={{
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <NoStoriesFound className={classes.noStoriesFound} />
          <div style={{ fontSize: 'larger' }}>No Stories Found</div>
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        margin: '20px 0',
        justifyContent: 'center',
        marginBottom: 65,
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
        {left.map((story, index) => (
          <Story key={index} app={app} story={story} showStory={showStory} />
        ))}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
        {right.map((story, index) => (
          <Story key={index} story={story} showStory={showStory} />
        ))}
      </div>
    </div>
  )
}

export default StoriesList
