import React from 'react'
import SearchPage from './SearchPage'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: 16,
    },
    search: {
      color: theme.palette.primary.main,
      fontSize: 18,
      boxSizing: 'border-box',
      margin: '15px 0',
    },
  }
})

export default function SearchPageWrapper(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.search}>Search: {props.searchValue}</div>
      <SearchPage {...props} />
    </div>
  )
}
