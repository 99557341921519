import React, { useState, useEffect, useRef } from 'react'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import { makeStyles } from '@material-ui/core/styles'
import CommentIcon from './comment_icon.png'
import Caption from './Caption'

import './Image.css'

const Annotation = (props) => {
    
  const {annot, edit} = props

  const [showText, setShowText] = useState(false)

  return (
    <div style={{
      position: 'absolute',
      left: annot.x,
      top: annot.y,
      minHeight: 75,
      boxSizing: 'border-box',
      color: '#666',
      fontFamily: 'Avenir, Avenir Next, \'Montserrat\', \'OpenSans\', Helvetica, Arial, sans-serif',
      lineHeight: 1.3,
      fontSize: 18
    }} onMouseEnter={() => setShowText(true)}
    onMouseLeave={() => setShowText(false)}>
      <div
        onClick={() => edit(annot)}
        style={{
          backgroundImage: `url(${CommentIcon})`,
          backgroundColor: 'rgba(0,0,0,.9)',
          backgroundPosition: 'center',
          backgroundSize: 26,
          backgroundRepeat: 'no-repeat',
          width: 50,
          height: 50,
          position: 'relative',
          borderRadius: '100%',
          border: '2px solid #fff',
        }} />
      <div className={showText ? 'fadeIn' : 'fadeOut'}
        style={{
          padding: '5px 5px 5px 16px',
          left: 50,
          textAlign: 'left',
          top: 0,
          maxWidth: 300,
          minWidth: 250,
          borderRadius: 2,
          background: 'rgba(0,0,0,0.6)',
          color: '#fff',
          position: 'absolute',
          zIndex: 0}}>
        {annot.text}
      </div>  
    </div>
  )
}


const useStyles = makeStyles({
  image: {
    width: '100%',
    minHeight: 500,
  }
})

function Image(props){

  const classes = useStyles()
  const { asset, currentSlide, keyboardDownClicked, index, isGallery  } = props

  const dummyRef = useRef(null)

  const [annotationsVisible, setAnnotationsVisibility] = useState(true)

  const _handleImageLoaded = () => {
    window.dispatchEvent(new Event('resize'))
  }

  useEffect(() => {
    if(keyboardDownClicked && currentSlide === index && isGallery){
      dummyRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [keyboardDownClicked])

  const toggleAnnotationsVisibility = () => {
    setAnnotationsVisibility(!annotationsVisible)
  }

  return(
    <Card className={classes.image}>
      <CardActionArea style={{position: 'relative'}}>
        <img alt={asset.name} src={asset.link} width={'100%'} onLoad={_handleImageLoaded} style={{height: '70vh', objectFit: 'contain'}}/>
        <Caption asset={asset} click={toggleAnnotationsVisibility} />
        {asset.annotations && asset.annotations.map((annot, index) => {
          return <Annotation key={index} annot={annot} annotationVisible={annotationsVisible} />
        })}
        <div ref={dummyRef} style={{position: 'absolute', bottom: 0, height: 10}}/>
      </CardActionArea>
    </Card>
  )
}

export default Image