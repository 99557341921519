import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Type from './Type'

const useStyles = makeStyles({
  footer: {
    padding: '10px 18px 26px 18px',
    fontSize: '105.01%',
    boxSizing: 'border-box',
    color: '#999'
  },
  root: {
    margin: '0 0 7.5px',
    fontSize: '105.01%',
    boxSizing: 'border-box'
  },
  glyphicon: {
    fontSize: '95%',
    paddingLeft: 5,
    paddingTop: 5,
    position: 'relative',
    top: 1,
    display: 'inline-block',
    fontFamily: 'Glyphicons Halflings',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 1
  },
  formGroup: {
    paddingTop: 5,
    marginBottom: 15,
    fontSize: '105.01%',
    boxSizing: 'border-box'
  } 
})



export default function MetaData(props){

  const classes = useStyles()

  const { metaData } = props

  const [show, setShow] = useState(false)
  const [hasContent, setHasContent] =  useState(false)

  useEffect(() => {
    if(!isEmpty()){
      setHasContent(true)
    }
    // eslint-disable-next-line
    }, []);

  const isEmpty = () => {
    for (const key in metaData) {
      if (Object.hasOwn(metaData, key)) {
        const m = metaData[key]
        if(key !== '_id' && key !== '__v' && m !== ''){
          return false
        }
      }
    }
    return true
  }

  const handleShowMetaData = () => {
    setShow(prevShow => !prevShow)
  }


  return (
    <div className={classes.footer} style={{display: hasContent ? 'block' : 'none'}}>
      <p className={classes.root}>
        <span onClick={handleShowMetaData}>
                Additional Info
          <span className={`${classes.glyphicon} ${show ? 'glyphicon-chevron-down' : 'glyphicon-chevron-right'}`}>
          </span>
        </span>
      </p>
      <div style={{display: show ? 'block' : 'none'}}>
        <div className={classes.formGroup}>
          <Type type={'Contributor'} value={metaData.contributor}/>
        </div>
        <div className={classes.formGroup}>
          <Type type={'Creator'} value={metaData.creator}/>
        </div>
        <div className={classes.formGroup}>
          <Type type={'Description'} value={metaData.description}/>
        </div>
        <div className={classes.formGroup}>
          <Type type={'Format'} value={metaData.format}/>
        </div>
        <div className={classes.formGroup}>
          <Type type={'Identifier'} value={metaData.identifier}/>
        </div>
        <div className={classes.formGroup}>
          <Type type={'Language'} value={metaData.language}/>
        </div>
        <div className={classes.formGroup}>
          <Type type={'Publisher'} value={metaData.publisher}/>
        </div>
        <div className={classes.formGroup}>
          <Type type={'Relation'} value={metaData.relation}/>
        </div>
        <div className={classes.formGroup}>
          <Type type={'Rights'} value={metaData.rights}/>
        </div>
        <div className={classes.formGroup}>
          <Type type={'Source'} value={metaData.source}/>
        </div>
        <div className={classes.formGroup}>
          <Type type={'Subject'} value={metaData.subject}/>
        </div>
        <div className={classes.formGroup}>
          <Type type={'Type'} value={metaData.type}/>
        </div>
      </div>
    </div>
  )
}