import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Timeline from './Timeline'
import withAuth from './withAuth'
import api from './exhibitApi'

import './App.css'

const TimelineWithAuth = withAuth(Timeline)

function App() {
  return (
    <Router>
      <Route path='/present/:appId/timeline' render={(props) => <TimelineWithAuth {...props} api={api} />} />
    </Router>
  )
}

export default App
