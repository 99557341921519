import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'

import Popup from './popup/Popup'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'
import Hidden from './hidden/Hidden'

import './App.css'
import Main from './Main'
import SearchPageWrapper from './SearchPageWrapper'
import MobileContext from './context/MobileContext'
import api from './exhibitApi'

const theme = createTheme({
  palette: {
    primary: blue,
  },
})

export default function Timeline(props) {
  const isMobile = useMediaQuery('(max-width: 700px)')

  const [app, setApp] = useState({})
  const [appHidden, setAppHidden] = useState(false)
  const [mapStyle, setMapStyle] = useState([])
  const [appLoaded, setAppLoaded] = useState(false)

  const appId = props.match.params.appId

  useEffect(() => {
    async function getAppInfo(id) {
      const app = await api.exhibit().getShallowById(id)      
      if (app.status === 'HIDDEN') {
        setAppHidden(true)
      } else {
        setApp(app)
        document.title = app.name
      }

      const style = await api.mapStyle().getByName(app.mapStyle)
      setMapStyle(style)
      setAppLoaded(true)
    }

    getAppInfo(appId)

  }, [appId])

  const [popupSettings, setPopupSettings] = useState({
    story: {},
    open: false,
  })

  const [searchValue, setSearchValue] = useState('')

  const openPopup = (story) => {
    setPopupSettings({
      story: { ...story },
      open: true,
    })
  }

  const handleButtonClicked = (story) => {
    openPopup(story)
  }

  const handleGoUpButtonClicked = () => {
    window.scrollTo(0, 0)
  }

  const closePopup = () => {
    setPopupSettings({
      story: {},
      open: false,
    })
  }

  const handleTagClicked = (tag) => {
    closePopup()
    setSearchValue(`#${tag.value}`)
    props.history.push(`${props.match.url}/search/${tag.value}`)
  }

  const fetchStory = async (storyId) => {
    const story = await api.story().getById(storyId)
    return story
  }

  const handleShowStory = async (storyId) => {
    const story = await fetchStory(storyId)
    if (story) {
      openPopup(story)
    }
  }

  return (
    <>
      {appLoaded && appHidden && <Hidden />}
      {appLoaded && !appHidden && (
        <MuiThemeProvider
          theme={
            app.mainColor
              ? createTheme({
                palette: {
                  primary: { main: app.mainColor },
                  secondary: { main: app.secondaryColor },
                },
              })
              : theme
          }
        >
          <MobileContext.Provider
            value={{
              isMobile: isMobile,
            }}
          >
            <Route
              exact
              path={props.match.url}
              render={(routeProps) => (
                <Main
                  {...routeProps}
                  app={app}
                  appId={appId}
                  isMobile={isMobile}
                  handleButtonClicked={handleButtonClicked}
                  handleGoUpButtonClicked={handleGoUpButtonClicked}
                />
              )}
            />

            <Route
              exact
              path={`${props.match.url}/search/:searchValue`}
              render={(props) => (
                <SearchPageWrapper
                  {...props}
                  appId={appId}
                  searchValue={searchValue}
                  showStory={handleShowStory}
                />
              )}
            />

            <Popup
              app={app}
              isMobile={isMobile}
              settings={popupSettings}
              tagClick={handleTagClicked}
              mapStyle={mapStyle}
              close={() =>
                setPopupSettings({
                  story: {},
                  open: false,
                })
              }
            />
          </MobileContext.Provider>
        </MuiThemeProvider>
      )}
    </>
  )
}
