import React from 'react'
import StoriesList from './StoriesList'

function Stories(props) {
  const { stories, showStory, showSingleCollection } = props

  return (
    <>
      <div
        style={{
          marginTop: showSingleCollection ? 500 : 0,
          fontWeight: 900,
          fontSize: 22,
          lineHeight: '1.2',
          letterSpacing: '-.7px',
        }}
      >
        Stories
      </div>
      <StoriesList stories={stories} showStory={showStory} />
    </>
  )
}

export default Stories
