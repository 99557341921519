import React, { useEffect, useState } from 'react'
import Year from './Year'

function VerticalProgress(props) {
  const { years, selectedYear, handleClick, isMobile } = props
  const distanceFromTop = 430
  const [timelineTop, setTimelineTop] = useState(distanceFromTop)

  const handleScroll = () => {
    const scrollTop = window.pageYOffset
    setTimelineTop(Math.max(290, distanceFromTop - scrollTop))
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div
      style={{
        display: isMobile ? 'none' : 'block',
        width: 100,
        paddingLeft: 35,
        paddingRight: 100,
        justifyContent: 'space-evenly',
        marginTop: 30,
      }}
    >
      <div
        style={{
          position: 'fixed',
          height: `calc(100vh - ${timelineTop+20}px)`,
          top: `${timelineTop}px`,
          overflowY: 'scroll',
          width: 100,
          overflowX: 'hidden',
        }}
      >
        <div>
          {years.map((year, index) => (
            <Year
              key={index}
              year={year}
              clicked={handleClick}
              selectedYear={selectedYear}
              isLast={index === years.length - 1}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default VerticalProgress
