// BUG: Audio player not full width in carousel
import React from 'react'
import ReactPlayer from 'react-player'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import Caption from './Caption'
import AudiotrackIcon from  './headphones-monochrome.png'


const useStyles = makeStyles({
  card: {
    position: 'relative',
    width: '100%',
    height: 500,
  }
})


function Audio(props){

  const classes = useStyles()
  const { asset }  = props

  const handleAudioReady = () => {
    window.dispatchEvent(new Event('resize'))
  }

  return(
    <Card className={classes.card}>
      <ReactPlayer style={{
        backgroundImage: `url(${AudiotrackIcon})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }} height={500} width={'100%'} url={asset.link} controls onReady={handleAudioReady}/>
      <Caption asset={asset} />
    </Card>
  )
}

export default Audio
