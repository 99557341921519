import React from 'react'
import { Button, makeStyles } from '@material-ui/core'

const styles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  text: {
    textAlign: 'center'
  }
})

export default function LoginPage({ api, loginResult }) {

  const classes = styles()

  const login = async () => {
    const res = await api.user().loginUser()
    loginResult(res)
  }

  return (
    <div className={classes.root}>
      <p className={classes.text}>You will need to login to view this content</p>
      <Button onClick={login}>login</Button>
    </div>
  )
}