import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import SpeedDial from '@material-ui/lab/SpeedDial'
import UpArrow from '@material-ui/icons/ArrowUpward'

import './GoUpButton.css'

const styles = (theme) => ({
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
  fab: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
})

class GoUpButton extends React.Component {
  render() {
    const { classes, click } = this.props

    return (
      <SpeedDial
        ariaLabel="Go Up Button"
        className={classes.speedDial}
        classes={{
          fab: classes.fab,
        }}
        open={false}
        hidden={false}
        icon={<UpArrow />}
        onClick={click}
      />
    )
  }
}

export default withStyles(styles)(GoUpButton)
