import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AudiotrackIcon from './headphones-monochrome.png'

const useStyles = makeStyles({
  actionArea: {
    lineHeight: 1.5,
    padding: '5px 4%',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    boxSizing: 'border-box',
    float: 'left',
    fontWeight: 700,
    fontSize: 14,
    letterSpacing: '-.3px',
    background: '#fff',
    color: '#333',
  },
  button: (props) => ({
    border: `1px solid ${props.app ? props.app.mainColor : ''}`,
    color: props.app ? props.app.mainColor : '',
    padding: '2px 5%',
    float: 'right',
    fontSize: 14,
    fontWeight: 600,
    borderRadius: 50,
    marginRight: 10,
    cursor: 'pointer',
  }),
  date: (props) => ({
    color: props.app ? props.app.mainColor : '',
    position: 'absolute',
    right: 20,
    top: 20,
    borderRadius: 20,
    padding: '4px 10px',
    background: 'rgba(255,255,255,.7)',
    fontWeight: 600,
    fontFamily: 'Montserrat',
    fontSize: 14,
    letterSpacing: '-.7px',
  }),
})

function Story(props) {
  const { story, showStory } = props

  const classes = useStyles(props)

  const [image, setImage] = useState('')

  useEffect(() => {
    if (story.image) {
      if (story.image.includes('.mp3')) {
        setImage(AudiotrackIcon)
      } else {
        setImage(story.image)
      }
    }
  }, [story.image])

  return (
    <div
      style={{ margin: '20px 0', padding: 10, position: 'relative' }}
      onClick={() => showStory(story._id)}
    >
      <img
        src={image}
        alt={story.title}
        style={{
          objectFit: 'contain',
          objectPosition: 'center',
          width: '100%',
          height: '100%',
          borderRadius: '8px 8px 9px 9px',
        }}
      />
      <div className={classes.date}>{story.formatted_date}</div>
      <div className={classes.actionArea}>
        {story.title}
        <div className={classes.button} onClick={() => showStory(story._id)}>
          View
        </div>
      </div>
    </div>
  )
}

export default Story
