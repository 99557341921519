import React, { useEffect, useState } from 'react'
import api from './exhibitApi'
import Stories from './Stories'


export default function SearchPage(props) {
  const { searchValue, showStory, appId } = props
  const [stories, setStories] = useState([])

  useEffect(() => {
    async function search(appId, term) {
      const stories = await api.story.search({appId, term})
      setStories(stories)
    }

    async function searchTags(appId, value) {
      const stories = await api.story.searchTags({appId, tag: value})
      setStories(stories)
    }

    if (searchValue === '') {
      setStories([])
    } else if (searchValue.charAt(0) === '#') {
      const value = searchValue.substring(1)
      searchTags(value)
    } else {
      search(appId, searchValue)
    }

    window.scrollTo(0, 0)

  }, [appId, searchValue])

  return <Stories stories={stories} showStory={showStory} />
}
