import React from 'react'
import MapsPin from './maps_pin.png'




const Marker = () => {

  return (
    <img  alt="Map Pin" src={MapsPin} style={{height: 50, position: 'relative'}}/>
  )
}

export default function MarkerWrapper(){
    
  return (
    <Marker />
  )
}