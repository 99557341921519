import React, { useState, useEffect } from 'react'
import LoginPage from './LoginPage'


export default function withAuth(WrappedComponent) {

  return function AuthComponent(props){
    const { api } = props

    const appId = props.match.params.appId

    const [accessAllowed, setAccessAllowed] = useState(false)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
      async function checkAuthAndHandleChanges(){
        const loginRequired = await api.user().requiresLogin(appId)
        if(loginRequired){
          api.user().registerAuthChangeListener(user => {
            setAccessAllowed(!!user)
            setLoaded(true)
          })
    
          return () => api.user().removeAuthStateListener()
          
        }else{
          setAccessAllowed(true)
          setLoaded(true)
        }
      }

      checkAuthAndHandleChanges()
    }, [appId])

    const handleLoginResult = async result => {
      setAccessAllowed(!!result)
      setLoaded(true)
    }

    return (
      <>
        {loaded && !accessAllowed && <LoginPage api={api} loginResult={handleLoginResult}/>}
        {loaded && accessAllowed && <WrappedComponent {...props} />}
      </>
    )
  }
}
